// We can't use Tailwind on the html generated with the markdown library so, yeah.
.bot-bubble {
  h3 {
    font-weight: bolder;
    font-size: large;
  }
  h4 {
    font-style: italic;
  }
}
