@import "../colors.scss";

.graph {
  position: relative;

  height: calc(100vh - 150px);
  padding: 2%;
  display: flex;
  flex-direction: column;

  svg {
    overflow: visible;
  }
}

@keyframes pulse-ready {
  0% {
    color: white;
  }

  50% {
    color: #02fca5;
  }

  100% {
    color: white;
  }
}

@keyframes pulse-not-ready {
  0% {
    color: #fff57b;
  }

  50% {
    color: #ec9a00;
  }

  100% {
    color: #fff57b;
  }
}

.graph.allValid {
  padding-left: 20px;
  transition: all 1s;
  border: none;
}

.need-help,
.switch-to-vr,
.dont-switch-to-vr {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.refresh-and-preview {
  position: absolute;
  top: 0;
  left: 0%;
  opacity: 1;
  display: grid;
}

.need-help {
  top: 0;
  right: 0;
  padding: 20px;
}

.dont-switch-to-vr {
  z-index: 1;
}

.dont-switch-to-vr > h1 {
  animation: pulse-not-ready 5s infinite linear;
  animation-direction: alternate;
  animation-fill-mode: linear;
}

.switch-to-vr {
  h1,
  svg {
    text-align: center;
    animation: pulse-ready 5s infinite;
    animation-direction: alternate;
    animation-fill-mode: linear;
  }
}

.need-help button + button {
  margin-left: 1em;
}

.graph.allValid > .switch-to-vr {
  opacity: 1;
  transition: all 1s;
  z-index: 0;
}

.graph.allValid > .dont-switch-to-vr {
  opacity: 0;
  transition: all 1s;
  z-index: -1;
}

.graph-title {
  color: black;
  font-family: Nunito;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  user-select: none;
}

.graph-sub-title {
  color: black;
  font-family: Nunito;
  font-weight: bold;
  font-size: 15px;
  font-style: italic;
  line-height: 27px;
  user-select: none;
}

.guide-modal {
  min-width: 60vw;

  .modal-body {
    min-width: inherit;
    width: inherit;
  }

  video {
    min-width: inherit;
    width: inherit;
  }
}

.switch-to-vr-subrow {
  display: flex;
  justify-content: center;
  align-items: none;
  user-select: none;

  .switch-to-vr-subcol {
    padding-right: 0;
    max-height: 6vh;
  }
}

.graph-tool-bar {
  align-self: center;
  position: absolute;
  top: 10px;

  button {
    border-style: none;
    background: none;
  }
}

.graph-tool-bar-tooltip {
  font-size: 15px;
}

#graph-svg {
  max-width: 100%;
  max-height: 100%;
}
