.s0 {
  fill: #000000;
}
.s1 {
  fill: url(#g1);
}
.s2 {
  fill: url(#g2);
}
.s3 {
  fill: url(#g3);
}
.s4 {
  fill: url(#g4);
}
.s5 {
  fill: url(#g5);
}
.s6 {
  fill: url(#g6);
}
.s7 {
  //   fill: #0082fb;
  fill: black;
}
.s8 {
  fill: url(#g7);
}
.s9 {
  fill: url(#g8);
}
.s10 {
  fill: url(#g9);
}
.s11 {
  fill: url(#g10);
}
.s12 {
  fill: url(#g11);
}
.s13 {
  fill: url(#g12);
}
.s14 {
  fill: url(#g13);
}
