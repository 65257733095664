@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, sans-serif;
    color: #212429;
  }
}

@layer components {
  .btn-primary-fill {
    @apply bg-green-500 text-gray-900 hover:bg-green-600 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed text-white border;
  }
  .btn-danger-fill {
    @apply bg-red-500 text-gray-900 hover:bg-red-600 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed;
  }

  .btn-secondary-fill {
    @apply bg-blue-500 hover:bg-blue-400 text-white disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed;
  }

  .btn-alternative-fill {
    @apply bg-gray-300 hover:bg-gray-400 text-gray-400 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed;
  }

  .btn-primary-outline {
    @apply border border-green-500 text-green-500 hover:border-green-600 hover:text-green-600 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed;
  }

  .btn-secondary-outline {
    @apply border border-blue-500 text-blue-500 hover:border-blue-400 hover:text-blue-400 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-lg font-medium disabled:cursor-not-allowed;
  }

  .btn-alternative-outline {
    @apply text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg py-2 px-3 min-w-[80px] disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .btn-dropdown-menu {
    @apply w-full relative justify-between items-center flex py-2 px-3 text-gray-700 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-slate-300;
  }

  .badge-sucess {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-green-300 text-black rounded-full;
  }

  .badge-warning {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-yellow-300 text-black rounded-full;
  }

  .badge-danger {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-red-300 text-black rounded-full;
  }

  .right-25 {
    right: 8rem;
  }
  .mt-25 {
    margin-top: 9rem;
  }
  select {
    height: 2.813rem;
    width: 12.5rem;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='12' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='rgb(0, 200, 137)' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-position: right 0.5rem top 1rem;
    background-size: 1.5em 1.5em;
  }

  @media only screen and (min-width: 768px) {
    .left-200 {
      left: 31.200000000000003rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .left-200 {
      left: 33.2rem;
    }
  }
  @media only screen and (min-width: 1060px) {
    .left-200 {
      left: 36.2rem;
    }
  }
  @media only screen and (min-width: 1280px) {
    .left-200 {
      left: 43.2rem;
    }
  }
  @media only screen and (min-width: 1536px) {
    .left-200 {
      left: 61.1rem;
    }
  }
  @media only screen and (min-width: 1735px) {
    .left-200 {
      left: 71.7rem;
    }
  }

  @media only screen and (min-width: 768px) {
    .left-300 {
      left: 39.2rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .left-300 {
      left: 40.2rem;
    }
  }
  @media only screen and (min-width: 1085px) {
    .left-300 {
      left: 44.3rem;
    }
  }
  @media only screen and (min-width: 1280px) {
    .left-300 {
      left: 51.3rem;
    }
  }
  @media only screen and (min-width: 1536px) {
    .left-300 {
      left: 69rem;
    }
  }
  @media only screen and (min-width: 1735px) {
    .left-300 {
      left: 84.6rem;
    }
  }

  .left-400 {
    left: 77.5rem;
  }
  .left-500 {
    left: 13.75rem;
  }
  .top-17 {
    top: 4.25rem;
  }
  .completely-centeredProfile {
    position: fixed;
    top: 50%;
    left: 55%;

    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .completely-centered {
    position: fixed;
    top: 45%;
    left: 45%;

    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .mr-fix-important {
    margin-right: -2vh !important; /* -28px  */
  }
}

.w-halfscreen {
  width: 30rem;
  /* 100vh */
}

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full block text-black bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-slate-300
    focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
    invalid:border-red-500   focus:invalid:border-red-500 focus:invalid:ring-red-500;
  }

  [type="checkbox"] {
    @apply rounded border-gray-300 text-green-500 focus:ring-green-500;
  }

  [type="radio"] {
    @apply rounded-full border-gray-300 text-green-500 focus:ring-green-500;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* For disabling react-router <NavLink> cf. https://stackoverflow.com/a/38321726 */
.disabled-link {
  cursor: not-allowed;
}
/* For circle avatar "google" */
.circle-google {
  transition: linear 0.25s;
}
.circle-google:hover {
  transition: ease-out 0.2s;
  border: 2px solid rgba(0, 0, 0, 0.664);
  -webkit-transition: ease-out 0.2s;
}

/* Courtesy of Baptiste */
.card-title-outline {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.search-filter {
  height: 4.5rem;
}
.pt-13 {
  padding-top: 3.3rem;
}

.text-xsmall {
  font-size: 0.7rem;
}

.button-enviroment3d {
  height: 16.188rem;
  width: 20rem;
}
.ml-51 {
  margin-left: 58rem;
}

.background-3d-model {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M75 12.5L137.5 53.125V96.875L75 137.5L12.5 96.875V53.125L75 12.5Z' stroke='%23ACB5BD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M75 137.5V96.875' stroke='%23ACB5BD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M137.5 53.125L75 96.875L12.5 53.125' stroke='%23ACB5BD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 96.875L75 53.125L137.5 96.875' stroke='%23ACB5BD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M75 12.5V53.125' stroke='%23ACB5BD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
}

.background-360-model {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='196' height='76' viewBox='0 0 196 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M140.285 63.1382V12.8613C113.397 14.4954 86.3928 14.4954 59.5156 12.8613V63.1382C86.3919 61.5041 113.396 61.5041 140.285 63.1382Z' fill='%23ACB5BD'/%3E%3Cpath d='M195.186 0C178.517 6.42015 161.512 10.6783 144.396 12.8185V63.1887C161.512 65.3214 178.517 69.587 195.186 76V0Z' fill='%23ACB5BD'/%3E%3Cpath d='M0.814132 76.0005C18.7635 69.5803 37.0744 65.3221 55.5047 63.1892L55.5116 12.819C37.0814 10.679 18.7705 6.42064 0.813965 0.000488281L0.814132 76.0005Z' fill='%23ACB5BD'/%3E%3C/svg%3E");
}

.background-audio {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://s3.eu-west-3.amazonaws.com/storage.wixar.io/note_opacity.png");
}

.background-pdf {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://s3.eu-west-3.amazonaws.com/storage.wixar.io/pdf_placeholder_transparent2.png");
}

.ml-background-music {
  margin-left: 22rem;
}

.checkbox-no-icon {
  cursor: pointer;
  content: "";
  width: 15.99px;
  height: 15.99px;
  border: 1px solid rgba(16, 22, 34, 0.15);
  background-color: rgb(253, 253, 253);
  border-radius: 3px;
}
.checkbox-icon {
  cursor: pointer;
  content: "";
  width: 15.99px;
  height: 15.99px;
  border: 1px solid rgba(16, 22, 34, 0.15);
  background-color: rgb(253, 253, 253);
  border-radius: 3px;
}
.checkbox-display > input {
  display: none;
}
.checkbox-no-icon::after {
  cursor: pointer;
  content: "";
}
.checkbox-icon::after {
  cursor: pointer;
  content: "-";
  position: absolute;
  top: calc(0% - 14px);
  left: 3px;
  font-size: 37px;
  font-weight: 100;
  color: black;
}

.dropdown {
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 14px 20px 14px 20px;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-body {
  display: none;
}

.dropdown-body.open {
  display: grid;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.stats-modal-end-user {
  width: 82.739%;
  height: 75.04765%;
}

@media only screen and (min-width: 720px) {
  .text-infocard-modal {
    font-size: 0.4rem;
    line-height: 0.95rem;
  }
  .title-infocard-modal {
    font-size: 0.55rem;
  }
}
@media only screen and (min-width: 1360px) {
  .text-infocard-modal {
    font-size: 0.6rem;
    line-height: 0.95rem;
  }
  .title-infocard-modal {
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1472px) {
  .text-infocard-modal {
    font-size: 0.7rem;
    line-height: 1.25rem;
  }
  .title-infocard-modal {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1578px) {
  .text-infocard-modal {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .title-infocard-modal {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 1826px) {
  .text-infocard-modal {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .title-infocard-modal {
    font-size: 1rem;
  }
}

/*
 *  STYLE 2
 */

.personalize-scroll-visible::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-left: 9.5rem;
}

.personalize-scroll-visible::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.personalize-scroll-visible::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00d18f;
}

.personalize-scroll-visible::-webkit-scrollbar-thumb:hover {
  background: #00c083;
}

@media only screen and (min-width: 1604px) {
  .overScroll1580px {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.modalContent {
  max-height: calc(100vh - 48px);
  overflow-y: auto;
}

@font-face {
  font-family: "LiberationSans SDF";
  src: url("./fonts/LiberationSans.ttf") format("truetype");
}

@font-face {
  font-family: "Prilly Monly SDF";
  src: url("./fonts/Prilly\ Monly.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold SDF";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SalmaPro-MediumNarrow SDF";
  src: url("./fonts/SalmaPro-MediumNarrow.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-VariableFont_wght SDF";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}
