$green: #02fca5;
$bluegreen: #45ffd6;
$red: #ff3400;
$blue-radian: linear-gradient(180.09deg, #152969 0.15%, #010038 77.2%);
$light-green-radian: linear-gradient(180deg, #00efa8 0%, #009360 100%);
$darkblue: #010038;
$darkblue2: #012557;
$white: white;
$lightblue: #1a2c6a;
$gray: darkgrey;
$yellow: yellow;
$lightyellow: #ffff9b;
$purple: #19013f;
$purple2: #21004f;
$purple3: #2b0067;
$lightblue2: #00bbd3;
$lightblue3: #41f2cf;
$published-green: #0bf468;
$waiting-for-publish-yellow: rgb(223 133 31);
$edit-blue: rgb(6 89 156);
$archived-grey: rgb(104 102 102);
$light-green: #41f2cf;

:export {
  green: $green;
  bluegreen: $bluegreen;
  red: $red;
  blueRadian: $blue-radian;
  darkblue: $darkblue;
  lightblue: $lightblue;
  white: $white;
  yellow: $yellow;
  publishedGreen: $published-green;
  waitingForPublishYellow: $waiting-for-publish-yellow;
  editBlue: $edit-blue;
  archivedGrey: $archived-grey;
}
