.node-scene-info,
.node-asset-info {
  color: white;
}

.node-scene-info {
  font-size: 25px;
}

.add-scene-button {
  cursor: pointer;
  font-size: 31px;

  path {
    transform: translate(0.4vw, -1.2vh);
  }
}

.node-asset-info {
  font-size: 20px;
}

.tooltip-wrapper {
  overflow: visible;

  .tooltip-text-asset {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 14px;
    opacity: 0.6;
    transform: translate(1.4vw, -2vh);
  }

  .tooltip-title-asset {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 17px;
    transform: translate(1.4vw, -4.2vh);
  }

  .tooltip-text-scene {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 14px;
    opacity: 0.6;
    transform: translate(5vw, -2vh);
  }

  .tooltip-title-scene {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 17px;
    transform: translate(5vw, -4.2vh);
  }

  .tooltip-text-add-scene {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 19px;
    opacity: 0.6;

    //transform: translate(11vw, 18.5vh);
  }

  .tooltip-title-add-scene {
    overflow: visible;
    visibility: hidden;
    min-width: 100%;
    min-height: 100%;
    font-size: 28px;

    //transform: translate(11vw, 15.8vh);
  }
}

.tooltip-wrapper:hover {
  .tooltip-text-scene,
  .tooltip-text-asset,
  .tooltip-title-scene,
  .tooltip-title-asset,
  .tooltip-text-add-scene,
  .tooltip-title-add-scene {
    visibility: visible;
  }
}
