@import "./colors.scss";

.project-edit-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-object-item {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2 !important;
  cursor: pointer;

  svg,
  p {
    margin: 0;
    text-align: center;
    font-weight: 700;
    color: $gray;
  }

  border: 1px solid $gray;
}

.project-object-item.active {
  border: 1px solid $lightblue;
  box-shadow: 0 0 10px 2px $green;

  svg,
  p {
    color: $green;
  }
}

.project-object-item.valided {
  background-color: $green;

  svg,
  p {
    color: $darkblue;
  }
}

.project-object-item.small {
  width: 40px;
  height: 40px;
  cursor: default;
}

.graph-upper-div {
  display: flex;
  flex-direction: column;
  position: relative;
}
