.bar-chart {
  height: 87% !important;
}

.recharts-surface {
  // g.recharts-pie {
  //   transform: translate(-6vw, -10vh);
  // }

  text {
    font-size: smaller;
  }
}

.recharts-legend-wrapper {
  scale: 0.8;
}

.recharts-responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-message {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 5vh;
}

.pie-legend .recharts-wrapper,
.pie-legend .recharts-surface,
.pie-legend .recharts-legend-wrapper {
  max-height: 100%;
}

.recharts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
