// Below is the code for the fancy gradient effects while scrolling in the  ButtonSquareIconsProjectGroupCreator component

.project-group-projects-container {
  position: relative;
  z-index: 0;
}

$GRADIENT_COLOR_DESTINATION: rgba(209, 213, 219, 1); // rgba(209, 213, 219, 1);
$GRADIENT_WIDTH_PERCENTAGE: 10%;

$GRADIENT_COLOR_START: rgba(255, 255, 255, 0);
$GRADIENT_LEFT: 100 - $GRADIENT_WIDTH_PERCENTAGE;

.right-gradient::before,
.left-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.right-gradient::before {
  left: $GRADIENT_LEFT;
  right: 0;
  background-image: linear-gradient(to right, $GRADIENT_COLOR_START, $GRADIENT_COLOR_DESTINATION);
}

.left-gradient::before {
  left: 9rem;
  width: $GRADIENT_WIDTH_PERCENTAGE;
  background-image: linear-gradient(to left, $GRADIENT_COLOR_START, $GRADIENT_COLOR_DESTINATION);
}

.left-and-right-gradient {
  position: relative;
  z-index: 0;
}

.left-and-right-gradient::before,
.left-and-right-gradient::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.left-and-right-gradient::before {
  left: $GRADIENT_LEFT;
  right: 0;
  background-image: linear-gradient(to right, $GRADIENT_COLOR_START, $GRADIENT_COLOR_DESTINATION);
}

.left-and-right-gradient::after {
  left: 9rem;
  width: $GRADIENT_WIDTH_PERCENTAGE;
  background-image: linear-gradient(to left, $GRADIENT_COLOR_START, $GRADIENT_COLOR_DESTINATION);
}

.cover-project-cards {
  position: absolute;
  top: 0;
  left: 0;
  height: 7.7rem;
  width: 100%;
  z-index: 9;
  pointer-events: none;
}

.project-card {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  z-index: -1;
}

.project-card:hover::before {
  background-size: 150%;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: -2;
}
